import clsx from 'clsx';
import React from 'react';
import { statusMapper } from '../../../../lib/utils/helpers/order-status-mapper';
import { OrderComputedStatus } from '../../../../lib/models/view-models/order';
import { FormatMoneyAmount } from '../../../../lib/utils/helpers/get-formated-price';
import { useCatalogTranslation } from '../../../../lib/hooks/use-catalog-translations';
import { Delivery, DeliveryTruck } from 'iconoir-react';
import { OrderCardProps } from '.';
import Image from 'next/image';

function OrderFromCart({
  displayId,
  entityId: orderId,
  status,
  date: orderDate,
  totalArticles,
  images,
  subtotal,
  currencyCode,
  handleClick,
}: OrderCardProps): JSX.Element {
  const { ct } = useCatalogTranslation('common');
  const imagesToDisplay = images?.slice(0, 2);

  return (
    <button
      data-testid="order-card"
      className="h-full"
      onClick={() => handleClick(orderId)}
    >
      <div className="flex flex-col gap-2 items-start">
        <div className="flex items-center gap-2">
          <div className="flex items-center justify-center w-7 h-7 bg-grey2-10 rounded-full">
            <Delivery className="w-4 h-4 text-grey2-60" />
          </div>
          <div
            data-testid="order-number"
            className={clsx(
              'text-header text-[16px] text-grey2-90',
              !displayId.includes('CATALOG_') ? 'font-semibold' : 'italic',
            )}
          >
            #{displayId}
          </div>
        </div>

        <div className="flex items-center gap-2">
          <div className="relative w-10">
            {imagesToDisplay.map((image, index) => {
              return (
                <div
                  key={`${image}${index}`}
                  className="absolute h-6 w-6 -top-3 rounded-md bg-grey-5"
                  style={{ zIndex: index, left: `${index * 12}px` }}
                >
                  <Image
                    layout="fill"
                    src={image}
                    alt="Product"
                    className="aspect-square w-full rounded-md border-2 border-white"
                  />
                </div>
              );
            })}
          </div>
          <div data-testid="totalArticles" className="text-sm text-grey2-90">
            {totalArticles}{' '}
            {ct('OrderOrCart.articles', {
              count: totalArticles,
            })}
          </div>
          <div
            data-testid="subtotal"
            className="text-sm font-semibold text-[#252C32]"
          >
            {FormatMoneyAmount(subtotal, currencyCode)}
          </div>
        </div>
        <div className="flex items-center gap-2">
          <DeliveryTruck className="text-seller" />
          <div
            className="text-base text-seller font-semibold underline"
            data-testid="order-label"
          >
            {ct('OrderCard.followOrder', 'Suivre ma commande')}
          </div>
        </div>
        <div className="flex flex-col gap-2 w-full mt-auto">
          <div className="w-full border-b border-grey2-10"></div>
          <div className="w-full flex justify-between items-baseline">
            <div className="text-base text-grey2-60" data-testid="order-date">
              {ct('OrderOrCart.smallDate', { date: new Date(orderDate) })}
            </div>
            <div className="pt-1" data-testid="status">
              {statusMapper(status as OrderComputedStatus)}
            </div>
          </div>
        </div>
      </div>
    </button>
  );
}

export default OrderFromCart;
